(function () {
  'use strict';

  angular
    .module('events.guests.view')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('events.guests.view', {
        url: '/:guestId',
        templateUrl: 'events/guests/view/view.tpl.html',
        controller: 'GuestsCtrl',
        controllerAs: 'vm'
      })
    ;
  }
}());
